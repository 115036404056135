import { vrColorPalette } from 'rut-components';

const colors = {
  brandColorPrimary:    vrColorPalette.primaries.red,
  brandColorPrimaryAlt: '#e52a3c',
  brandColorSecondary:  vrColorPalette.primaries.blue,
  text:                 '#000',
  textInverted:         '#fff',
  siteBackground:       vrColorPalette.backgrounds.white,
  ctaBackground:        '#f7f7f7',
  primaryButton:        vrColorPalette.primaries.red,
  // The following alterations of primary color comes from the file semantic.less
  primaryButtonActive:  '#9d080d',
  primaryButtonHover:   '#bd0107',
  primaryButtonFocus:   '#af0005',
  lightGrey:            '#b4b4b4',
  darkGrey:             '#707070',
  sidebarBackground:    '#ffd8d9'
};

export default colors;
export { vrColorPalette };
